body {
  font-family: "Roboto", sans-serif;
  background-color: #f8efe8;
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.login-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
}

.login-modal .overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.login-modal .modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  width: 400px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.login-modal .modal p {
  margin: 0;
}

.login-modal .modal .head {
  text-align: center;
  padding: 15px 15px 10px 15px;
}

.login-modal .modal .head .title {
  margin: 0;
}

.text-center {
  text-align: center;
}

.login-modal .modal .body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
}

.login-modal .modal .body input {
  width: 90%;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 15px;
  padding-left: 10px;
}

.login-modal .modal .bottom-container {
  display: flex;
  justify-content: center;
}

.login-modal .modal .bottom {
  display: flex;
  justify-content: center;
  padding: 0 5px 15px;
}

.login-modal .modal .bottom .btn-submit {
  color: white;
  background-color: #0873e9;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
}

.login-modal .modal .bottom .btn-cancel {
  color: white;
  background-color: #838282;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
}

.login-modal .modal .bottom .btn-submit.disabled {
  opacity: 0.5;
  background-color: #ccc;
  pointer-events: none;
}

.login-modal .modal .error {
  font-size: 14px;
  color: red;
  text-align: center;
  margin-bottom: 10px;
}

.change-language-action {
  position: absolute;
  right: 75px;
}

.change-language-action img {
  width: 20px;
  cursor: pointer;
}

.change-language-action img:first-child {
  margin-right: 10px;
}
